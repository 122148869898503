@import url(https://fonts.googleapis.com/css?family=Open+Sans);


#tickr-box {
    background: #C0C0C0;
    /*    background: #fff;*/
    max-width: 100%;
    padding: 3px;
    margin: 0 auto 0 auto;
    border-radius: 4px;
    cursor: pointer;
}

.tickr-title {
    color: #FFFFFF;
    font: bold 18px arial, sans-serif;
    padding: 5px;
    border-radius: 4px;
}

#tickr-scroll ul {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    list-style: none;
}

#tickr-scroll {
    background: transparent;
    height: 27px;
    margin: 3px auto 0 auto;
    overflow: hidden;
    padding: 0;
    border-radius: 4px;
}

#tickr-scroll li {
    color: #000000;
    font: normal 18px arial, sans-serif;
    line-height: 26px;
}

#tickr-scroll a {
    color: #0066FF;
    text-decoration: none;
}

#tickr-scroll a:hover {
    color: #3399FF;
    text-decoration: none;
}

#tickr-scroll ul {
    padding: 0 6px 0 6px;
    -webkit-animation: tickr 12s cubic-bezier(1, 0, .5, 0) infinite;
    animation: tickr 12s cubic-bezier(1, 0, .5, 0) infinite;
}

#tickr-scroll ul:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

@-webkit-keyframes tickr {
    0% {
        margin-top: 0;
    }
    25% {
        margin-top: -26px;
    }
    50% {
        margin-top: -52px;
    }
    75% {
        margin-top: -78px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes tickr {
    0% {
        margin-top: 0;
    }
    25% {
        margin-top: -26px;
    }
    50% {
        margin-top: -52px;
    }
    75% {
        margin-top: -78px;
    }
    100% {
        margin-top: 0;
    }
}

@media handheld and (max-width: 482px), screen and (max-device-width: 482px), screen and (max-width: 482px) {

    .tickr-title {
        font-size: 14px;
    }

    #tickr-scroll li {
        font-size: 12px;
    }

}
.pagination .page-item.activo .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  margin: 0px !important;
}
.CreditCard {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  position: relative;
/*  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/
  overflow: hidden;
  margin: 20px auto;
}

.CreditCard-header {
  width: 100%;
  height: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
}

.CreditCard-logo {
  width: 50px;
  height: 50px;
}

.CreditCard-info {
  width: 100%;
  height: 40%;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.CreditCard-number {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.CreditCard-holder {
  font-size: 18px;
  margin-bottom: 10px;
}

.CreditCard-expiry {
  font-size: 14px;
}

h1 {
  font-weight: 300;
}

.title {
  margin-bottom: 25px;
  color: #AEB6BF;
  font-size: 18px;
}
.title span {
  color: #5DADE2;
}
.content {
  width: 640px;
  margin:  45px auto;
}
  .tabs-body {

  }
    .tabs-header {
      display: block;
      width: 100%;
      list-style-type: none;
      margin-bottom: 50px;
      position: relative;
      z-index: 99;
    }
      .tabs-header li {
        display: table-cell;
        text-align: center;
        color: #ECF0F1;
        cursor: pointer;
      }
        .tabs-header li > a {
          display: block;
          padding: 15px;
          background: #212F3D;
          transition: all .2s ease-in;
          transform: skew(-40deg);
        }
          .tabs-header li > a span {
            display: block;
            transform: skew(40deg);
          }
        .tabs-header li > a:hover {
          background: #2471A3;
          color: #F7F9F9;
        }
        .tabs-header li.active > a {
          background: #2471A3;
        }
    .tabs-content {
      margin-top: 15px;
      position: relative;
      padding: 20px 25px;
      overflow: hidden;
      background: transparent;
    }
      .tabs-content .tabs-textItem {
        display: none;
        transform: translateY(100%);
      }
      .tabs-content .tabs-textItem.show {
        display: block;
        transform: translateY(0);
        -webkit-animation: change .49s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                animation: change .49s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
      .tabs-content .tabs-textItem p {
        font: normal 15px/1.5 'Open Sans';
        color: #F2F3F4;
      }

@-webkit-keyframes change {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes change {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.header-area {
    top: 0 !important;
}

.ba-navbar-show {
    z-index: 9999 !important;
}
